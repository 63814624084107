<template>
  <div class="main-container">
    <ProfileComponent />
  </div>
</template>

<script>
import ProfileComponent from "../modules/profile/Profile";

export default {
  name: "Profileview",
  components: {
    ProfileComponent,
  },
};
</script>
